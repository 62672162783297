<template>
  <div class="h-full overflow-hidden">
    <a-spin :spinning="loading">
      <iframe v-if="ctrl" class="webview" :src="src" @load="load" ref="iframe"></iframe>
    </a-spin>
  </div>
</template>

<script>
import { generateSsoUrl, menuNavigate } from '@/service/navigate'
import treeUtils from '@/utils/treeUtils'
export default {
  data() {
    return {
      loading: true,
      ctrl: true,
    }
  },
  methods: {
    load() {
      this.loading = false
    },
    reloadIframe() {
      this.ctrl = false
      this.$nextTick(() => {
        this.ctrl = true
      })
    },
  },
  computed: {
    src() {
      if (this.$route.path === '/m/_todo0_') {
        return this.$store.state.user.todoList[0].url
      }
      if (this.$route.path === '/m/_todo1_') {
        return this.$store.state.user.todoList[1].url
      }
      if (this.$route.path === '/m/_todo2_') {
        return this.$store.state.user.todoList[2].url
      }
      return generateSsoUrl(this.$route.meta.url)
    },
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.loading = true
        if (this.$route.path === '/m/__xiangmuweidu6856__') {
          const menu = treeUtils.find(this.$store.state.permission.menus, (item) => {
            return item.meta.code === 'ceshi656'
          })
          // console.log(menu)
          menuNavigate(menu)
        }
      },
    },
  },
}
</script>

<style lang="less" scoped>
.webview {
  width: 100%;
  height: calc(100vh - 90px);
  border: 0;
  display: block;
  background: #fff;
}
</style>
